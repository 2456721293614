import React, { useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import pipe from 'lodash/fp/pipe'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Section from 'tcweb-material-components/core/section'
import { encodeQueryData } from 'tcweb-material-components/core/utilities/helpers'
import filter from 'tcweb-material-components/core/poly/filter'
import count from 'tcweb-material-components/core/poly/count'

const useClasses = makeStyles(({ breakpoints }) => ({
    showMoreContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    showMoreButton: {
        marginTop: '1em',
        [breakpoints.up('md')]: { width: 'calc(100vw / 3)' }
    }
}))

const updateQuerystring = (params, enablePagination, page) => {
    const stringData = { ...params }

    if (enablePagination && page > 1) {
        stringData.page = page
    }

    {
        const cleanStringData = pipe(filter(Boolean), encodeQueryData)(stringData)
        if (cleanStringData.length) {
            window.history.replaceState(null, null, `?${cleanStringData}`)
        } else {
            window.history.replaceState(null, null, window.location.pathname)
        }

    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'set':
            return { ...state, ...action.payload }
        case 'search': {
            const { data, page, perPage, onSearch, initialized, enablePagination, enableQuerystring } = state
            const { params, initialPerPage } = action.payload
            const filteredData = onSearch(data, params)
            const currentPage = initialized ? 1 : page
            const currentPageData = enablePagination ? filteredData.slice(0, initialPerPage + perPage * (currentPage - 1)) : filteredData

            if (enableQuerystring) {
                updateQuerystring(params, enablePagination, currentPage)
            }

            return {
                ...state,
                params,
                page: currentPage,
                filteredData,
                currentPageData,
                loading: false,
                initialized: true
            }
        }
        case 'nextPage': {
            const { page, params, perPage, filteredData, initialPerPage, enablePagination, enableQuerystring } = state
            const currentPage = page + 1

            if (enableQuerystring) {
                updateQuerystring(params, enablePagination, currentPage)
            }

            return {
                ...state,
                page: currentPage,
                currentPageData: enablePagination ? filteredData.slice(0, initialPerPage + perPage * page) : filteredData
            }
        }
        default:
            throw new Error(`Invalid action type passed to reducer: ${action.type}`)
    }
}

const Searchable = ({
    data,
    params,
    children,
    enablePagination,
    perPage,
    nextPageComponent,
    page = 1,
    initialPerPage = perPage,
    enableQuerystring = true,
    emptyComponent = null,
    loadingComponent = null,
    onSearch = (data) => data,
    ...rest
}) => {
    const _classes = useClasses()
    const { t } = useTranslation()
    const [state, dispatch] = useReducer(reducer, {
        data,
        page,
        perPage,
        onSearch,
        initialPerPage,
        enablePagination,
        enableQuerystring,
        loading: true,
        initialized: false
    })
    const onNextPage = () => {
        dispatch({ type: 'nextPage' })
    }
    const totalNumItems = count(state.filteredData)
    const hasItems = totalNumItems != 0
    const numItems = count(state.currentPageData)

    // Params updated
    useEffect(() => {
        if (JSON.stringify(params) !== JSON.stringify(state.params)) {
            dispatch({ type: 'set', payload: { loading: true } })
            dispatch({ type: 'search', payload: { params, initialPerPage  } })
        }
    }, [params, initialPerPage])

    // Update state with new prop values
    useEffect(() => {
        dispatch({
            type: 'set',
            payload: {
                data,
                page,
                perPage,
                enableQuerystring,
                enablePagination
            }
        })
    }, [data, enableQuerystring, enablePagination, perPage, page])

    if (state.loading) return loadingComponent

    return (
        <Section {...rest}>
            {children({ data: state.currentPageData, params, page: state.page, total: totalNumItems, products: state.data })}
            <div className={_classes.showMoreContainer}>
                <Typography align='center' aria-live='polite'>
                    {!hasItems && emptyComponent}
                    {hasItems && t('slug:showing-1-to-post-of-total', { post: numItems.toString(), total: totalNumItems })}
                </Typography>
                {hasItems && numItems < totalNumItems &&
                    React.cloneElement(nextPageComponent, {
                        className: _classes.showMoreButton,
                        onClick: onNextPage
                    })}
            </div>
        </Section>
    )
}

export default Searchable
